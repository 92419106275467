import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../components/MainLayout/main-layout';
import config from '../../data/SiteConfig';
import Imprint from '../components/Imprint/imprint';

export default function ImprintPage() {
  return(
    <MainLayout>
      <Helmet title={`Imprint | ${config.siteTitle}`} />
      <Imprint />
    </MainLayout>
  );
}
